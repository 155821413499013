/*AMP LEFT CSS */

.amp_left_heading {
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  margin-bottom: 40px;
  align-items: center;
  color: #111727;
}

.amp_left_pages {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000;
}

.amp_check {
  opacity: 0.3;
  margin-bottom: 24px;
}

.amp_check_active {
  opacity: 1;
  margin-bottom: 24px;
}

.amp_left_button {
  background: #cd1d33;
  border: none;
  color: #fff;
  margin-top: 75px;
  border-radius: 6px;
  width: 140px;
  height: 32px;
}

/*AMP PAGE 1 */

.amp_page1_heading {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #111727;
}

.amp_sub_heading {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #9fa19f;
}

.amp_label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* Text / Grey */

  color: #9fa19f;
}

.next_sect_btn {
  background: #ffffff;
  height: 28px;
  width: 146px;
  border: 1px solid #747587;
  color: #747587;
  box-sizing: border-box;
  border-radius: 6px;
}

.prev_sect_btn {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  background: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #a9afb0;
}

/*AMP PAGE 3*/

.p_writing {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;

  /* Text/Black */

  color: #353439;
}

.add_btn_l {
  width: 30px;
  height: 30px;
  border: 1px solid #747587;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
}
