@media print {
  body * {
    visibility: hidden;
  }
  .divname,
  .divname * {
    visibility: visible;
  }
  .divname {
    overflow: visible !important;
    float: none !important;
    position: absolute;
    left: 0px;
    top: 0px;
    display: block !important;
    height: 500px !important;
  }
  /*p {
        page-break-before: always;
    }*/
}
.para {
  font-size: x-large;
  height: auto;
}

.general_table {
  border-collapse: collapse;
  width: 100%;

  background-color: #000;

  border-radius: 5px 4px 0px 0px;
}

.general_table th {
  padding: 10px;

  color: #000;
  background-color: #e2e2e2;
  font-style: normal;
  font-weight: 600;
  border: 1px solid #000;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
}

.general_table td {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  padding: 7px;

  text-align: center;
  background-color: #f4f4f4;
  color: #000;
}

.value_table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 8px;

  border-radius: 5px 4px 0px 0px;
}

.value_table th {
  color: #000;
  background-color: #e2e2e2;
  font-style: normal;
  font-weight: 600;

  font-size: 10px;
  line-height: 8px;
  text-align: left;
  text-transform: uppercase;
}

.value_table td {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 5px;
  padding: 7px;
  text-align: center;

  color: #000;
}

.table_total_block {
  border: 1px solid #000;
  font-weight: bold;
  background-color: #F4F4F4;
}

.house_name {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;

  /* Brand / Main */

  color: #cd1d33;
}

.house_address {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  /* or 150% */
  color: #000000;
}

.summary_claim {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  /* or 150% */
  text-transform: uppercase;
  color: #000000;
}

.member_details_p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* or 150% */
  display: inline-block;

  color: #000000;
}

/*THIS IS THE PRINT TRANSACTION BELOW STUFF */

.button_del {
  background-color: #ff5a5d;
  color: #fff;
  border-radius: 5px;
  height: 30px;
  border: none;
}

.folha {
  font-weight: bolder;
  font-size: 14px;
  line-height: 12px;
  /* identical to box height, or 120% */

  align-items: center;
  text-transform: uppercase;

  color: #cd1d33;
}

.sinistros,
.sinistros th,
.sinistros td {
  border: 1px solid black;
  border-collapse: collapse;
}

.sinistros th {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #000;
}

.sinistros td {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 150% */

  text-align: left;

  color: #000000;
}

.sin {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;

  font-size: 10px;

  line-height: 0px;
  /* identical to box height, or 150% */
  height: 0px;

  color: #ffffff;
}

.centerly {
  text-align: center;
  border: 3px solid green;
  height: 20px;
  vertical-align: top;
}

.cla {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  /* identical to box height, or 120% */
  vertical-align: middle;
  height: 20px;
  color: #000000;
}

.request_by {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 0px;
  /* identical to box height, or 150% */

  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: #000000;
}

.red_text {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 120% */

  align-items: center;
  text-align: center;

  /* Brand / Main */

  color: #cd1d33;
}

.paid {
  background-color: green;
}
.no_paid {
  background-color: #fff;
}

/*PRINT INVOICE PAGE STARTS HERE */

.print_invoice {
  border-collapse: collapse;
  padding: 0px;
  width: 100%;
}

.print_invoice td,
#print_invoice th {
  border: 1px solid #000000;
  white-space: nowrap;
  padding: 0px;
}

.print_invoice th {
  padding: 0px;
  height: 5px;
  background-color: #e2e2e2;
  color: #000;
  text-transform: uppercase;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.table-invoice-footer {
  width: 2%;
  text-align: left;
  font-size: 8px;
  text-transform: uppercase;
  border-right: 1px solid #000;
  font-weight: bold;
}

.invoice-footer-container {
  position: absolute;
  left: 24px;
  top: 800px;
}
