.back_to_m {
    width: 135px;
    height: 6.8vh;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 6.8vh;
    border: none;
    background-color: #fff;
  
    color: #747587;
  }
  
  .tab_btn {
    width: 114px;
    height: 44px;
    left: 32px;
    top: 123px;
    color: #a9afb0;
    opacity: 0.6;
    background: #fafafa;
    border: 1px solid #d3dae6;
  }
  
  .header_member {
    width: 100vw;
    height: 6.5vh;
  
    background-color: #111727;
  }
  
  /* width */
  ::-webkit-scrollbar {
    margin-top: 500px;
    width: 10px;
    height: 50px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  

  .link_btn{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    
    color: #007CEE;
    }