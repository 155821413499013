.save_plan_btn {
  background: #cd1d33;
  border-radius: 6px;
  width: 140px;
  height: 32px;
  margin-top: 30px;
  border: none;
  color: #fff;
}

.save_acc {
  background: #ffffff;
  /* Grey/Outline */
  height: 32px;
  width: 56px;
  border: 1px solid #d3dae6;
}

.main_btn {
  width: 171px;
  height: 32px;
  border: 1px solid #d3dae6;
  background-color: #fefefe;
  color: #000;
  margin-left: 19vh;
}

.main_btn_clicked {
  background-color: #d3d3d3;
  width: 171px;
  height: 32px;
  border: 1px solid #d3dae6;
  color: #000;
  margin-left: 19vh;
}
