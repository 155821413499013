@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.background {
  background: #fafafa;
  height: 102vh;
  width: 105%;
  margin-left: -20px;
  margin-top: -10px;
}
body {
  overflow: hidden;
}

.form {
  text-align: center;
  position: absolute;

  left: calc(50% - 520px / 2);
  top: calc(40% - 443px / 2 + 0.5px);
}

.form_outline {
  margin: auto;
  text-align: center;
  width: 448px;
  height: 400px;
  background: #fafafa;
  border: 4px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
}

.login_button {
  margin-top: 40px;
  width: 382px;
  height: 40px;
  background: #cd1d33;
  border-radius: 6px;
  border: none;
  font-size: 15px;
  cursor: pointer;
  color: #ffffff;
}

.input_username {
  width: 382px;
  margin-top: 34px;
  height: 42px;
  font-size: 15px;
  color: #a9afb0;
  border: 1px solid #d3dae6;
  box-sizing: border-box;
  border-radius: 5px;
}

.input_password {
  width: 382px;
  margin-top: 8px;
  height: 42px;
  color: #a9afb0;
  font-size: 15px;
  border: 1px solid #d3dae6;
  box-sizing: border-box;
  border-radius: 5px;
}

.Login_thing {
  margin-top: 40px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  align-items: center;
  color: #111727;
}
.login_subtitle {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height */
  text-align: center;
  align-items: center;
  color: #6a6a6a;
}

.image {
  position: absolute;
  margin-left: -180px;
  margin-top: -250px;
}

.panel {
  display: inline-block;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #ffffff;
}

.labels {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 0px;
  display: block;
  color: #4a4e53;
}

.loader {
  border: 14px solid #f3f3f3;
  border-radius: 50%;
  border-top: 14px solid #cd1d33;
  border-bottom: 14px solid #cd1d33;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 0.9s linear infinite;
  animation: spin 0.9s linear infinite;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
  justify-content: center;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.member_table {
  border-collapse: collapse;
  width: 95%;

  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  border-radius: 5px 4px 0px 0px;
}


thead {
  text-align: left;
  color: #94979a;
}
.member_table th {
  padding: 10px;
  font-size: 15px;
  color: #747587;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-transform: uppercase;
}

.member_table td {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;

  align-items: center;

  color: #333333;
}

.span_member {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-top: 3px;

  display: flex;

  align-items: center;

  color: #949292;
}

tr.main_table_inside {
  border-top: 1px solid #cccfd8;
}

td.main_table_inside {
  font-size: 55px;
}

.center {
  text-align: center;
  margin-top: 5px;
}

.full_bar {
  width: 98%;
  display: inline-block;
}

.right_end {
  width: 28%;
  display: inline-block;
}

.pagination {
  display: inline-block;
  width: 57%;
}

.pagination a {
  color: black;
  float: left;
  padding: 4px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.block_arrow {
  vertical-align: top;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.select_p_page {
  background: #f5f5f5;
  width: 56px;
  height: 20px;
  text-align: center;
  border: 1px solid #dadada;
  box-sizing: border-box;
}


.members_head{
  border:none
}


.orange{background-color: #d1d1d1;
color: #fff;}
.main-page {
  background-color: hsl(0, 0%, 98%);
  height: 99vh;
}

body {
  height: 100vh;

  cursor: default;
}

.header {
  width: 100vw;
  height: 5.5vh;

  background-color: #111727;
}

nav {
  width: 100vw;
  height: 5.5vh;
  background-color: #111727;
}

.div_header {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 5.5vh;
  align-items: center;
}

.logo {
  height: 40px;
  width: 116px;

  display: block;
}

.p_header {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  width: 100px;
  display: inline-flex;
  align-items: center;

  color: #ffffff;
}

.active {
  border-bottom: 4px solid #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  width: 100px;
  display: inline-flex;
  align-items: center;

  color: #ffffff;
  height: 29px;
}

.btn_header {
  background: #111727;
  border: none;
  border-left: 1px solid #ffffff;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  height: 5.5vh;
  display: inline-flex;
  align-items: center;
  width: 147px;
}

.subheader {
  width: 99.85%;
  height: 17.4%;
  margin-top: 2px;
  background-color: #fff;
}

.top_pagebox {
  width: 95%;
  height: 85%;
}

.subheader_heading {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 0px;
  /* identical to box height */

  align-items: center;

  color: #000000;
}

.labels {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  align-items: center;
  letter-spacing: 0.02em;
  color: #353439;
  opacity: 0.9;
}

.inputs_sidebar {
  background: #fefefe;
  border: 1px solid #cbcfd5;
  box-sizing: border-box;
  border-radius: 4px;
  color: #171f29;
  font-size: 13px;

  height: 28px;
}

.search_btn {
  width: 115px;
  height: 28px;
  border: none;
  border-radius: 6px;
  border: 1px solid #cd1d33;

  background-color: #fff;
  color: #cd1d33;
}

.trash_btn {
  height: 40px;
  background-color: #fff;
  color: #747587;
  border: 1px solid #747587;
  box-sizing: border-box;
  border-radius: 6px;
}

.tab_block {
  width: auto;
  border: none;
  background-color: #fff;
}

.tab_h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #333333;
}

.add_plan_btn {
  background-color: #cd1d33;
  border: none;

  color: #fff;
  border-radius: 6px;
  font-size: 15px;
  line-height: 18px;
  height: 32px;
  width: 150px;
  display: flex;
  align-items: center;
  text-align: center;
}

.new_member_btn {
  background-color: #cd1d33;
  border: none;

  color: #fff;
  border-radius: 6px;
  font-size: 15px;
  line-height: 18px;
  height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 10.5%;
}

.button_request {
  width: 96px;
  height: 32px;
  border: none;
  color: #fff;
  background: rgba(60, 119, 188, 0.9);
  border-radius: 5px;
  margin-left: 77px;
  display: inline-block;
}

.modal_title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  align-items: center;

  color: #111727;
}

.ModalBody {
  width: '50px';
  height: '200px';
  margin-left: 40px;
  margin-right: 40px;
}

.sub_header_head {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  /* Text / Grey */

  color: #9fa19f;
}

.new_member_btn {
  background-color: #cd1d33;
  border: none;

  color: #fff;
  border-radius: 6px;
  font-size: 15px;
  line-height: 18px;
  height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 10.5%;
}

.inputs {
  background: #fefefe;
  border: 1px solid #cbcfd4;
  box-sizing: border-box;
  display: block;
  margin-bottom: 24px;

  border-radius: 4px;
  color: #171f29;
  font-size: 13px;
  height: 30px;
}

.sub_title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #616161;
}

.submitt_btn {
  width: 104px;
  height: 32px;

  margin-top: 20px;

  color: #ffffff;
  background-color: #35af49;
  margin-right: 130px;
  border: 1px solid #35af49;
  border-radius: 5px;
}

.cancell_btn {
  width: 104px;
  height: 32px;
  background: #ffffff;
  margin-top: 20px;
  color: #2660a4;
  margin-right: 30px;

  border: 1px solid #fff;
  border-radius: 5px;
}

.submitTrans_header {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height */
  align-items: center;
  letter-spacing: 0.05em;
  /* App Bar */
  color: #111727;
}

.submitTrans_cancel {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  width: 80px;
  align-items: center;
  letter-spacing: 0.0125em;
  border: none;
  background-color: #fff;
  color: #747587;
}

.submitTrans_body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  align-items: center;
  letter-spacing: 0.05em;
  /* Text/Black */
  color: #353439;
}

.submitTrans_confirm {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  line-height: 18px;
  background-color: #fff;
  width: 180px;
  border: none;
  color: #0a6bf0;
}


.input_block{background: #FEFFFF;
  border: 1px solid #C8C8C8;
  height: 32px;
  box-sizing: border-box;
  border-radius: 2px;}


 .trans_selected{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.6;} 
@media print {
  body * {
    visibility: hidden;
  }
  .divname,
  .divname * {
    visibility: visible;
  }
  .divname {
    overflow: visible !important;
    float: none !important;
    position: absolute;
    left: 0px;
    top: 0px;
    display: block !important;
    height: 500px !important;
  }
  /*p {
        page-break-before: always;
    }*/
}
.para {
  font-size: x-large;
  height: auto;
}

.general_table {
  border-collapse: collapse;
  width: 100%;

  background-color: #000;

  border-radius: 5px 4px 0px 0px;
}

.general_table th {
  padding: 10px;

  color: #000;
  background-color: #e2e2e2;
  font-style: normal;
  font-weight: 600;
  border: 1px solid #000;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
}

.general_table td {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  padding: 7px;

  text-align: center;
  background-color: #f4f4f4;
  color: #000;
}

.value_table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 8px;

  border-radius: 5px 4px 0px 0px;
}

.value_table th {
  color: #000;
  background-color: #e2e2e2;
  font-style: normal;
  font-weight: 600;

  font-size: 10px;
  line-height: 8px;
  text-align: left;
  text-transform: uppercase;
}

.value_table td {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 5px;
  padding: 7px;
  text-align: center;

  color: #000;
}

.table_total_block {
  border: 1px solid #000;
  font-weight: bold;
  background-color: #F4F4F4;
}

.house_name {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;

  /* Brand / Main */

  color: #cd1d33;
}

.house_address {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  /* or 150% */
  color: #000000;
}

.summary_claim {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  /* or 150% */
  text-transform: uppercase;
  color: #000000;
}

.member_details_p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* or 150% */
  display: inline-block;

  color: #000000;
}

/*THIS IS THE PRINT TRANSACTION BELOW STUFF */

.button_del {
  background-color: #ff5a5d;
  color: #fff;
  border-radius: 5px;
  height: 30px;
  border: none;
}

.folha {
  font-weight: bolder;
  font-size: 14px;
  line-height: 12px;
  /* identical to box height, or 120% */

  align-items: center;
  text-transform: uppercase;

  color: #cd1d33;
}

.sinistros,
.sinistros th,
.sinistros td {
  border: 1px solid black;
  border-collapse: collapse;
}

.sinistros th {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #000;
}

.sinistros td {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 150% */

  text-align: left;

  color: #000000;
}

.sin {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;

  font-size: 10px;

  line-height: 0px;
  /* identical to box height, or 150% */
  height: 0px;

  color: #ffffff;
}

.centerly {
  text-align: center;
  border: 3px solid green;
  height: 20px;
  vertical-align: top;
}

.cla {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  /* identical to box height, or 120% */
  vertical-align: middle;
  height: 20px;
  color: #000000;
}

.request_by {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 0px;
  /* identical to box height, or 150% */

  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: #000000;
}

.red_text {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 120% */

  align-items: center;
  text-align: center;

  /* Brand / Main */

  color: #cd1d33;
}

.paid {
  background-color: green;
}
.no_paid {
  background-color: #fff;
}

/*PRINT INVOICE PAGE STARTS HERE */

.print_invoice {
  border-collapse: collapse;
  padding: 0px;
  width: 100%;
}

.print_invoice td,
#print_invoice th {
  border: 1px solid #000000;
  white-space: nowrap;
  padding: 0px;
}

.print_invoice th {
  padding: 0px;
  height: 5px;
  background-color: #e2e2e2;
  color: #000;
  text-transform: uppercase;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.table-invoice-footer {
  width: 2%;
  text-align: left;
  font-size: 8px;
  text-transform: uppercase;
  border-right: 1px solid #000;
  font-weight: bold;
}

.invoice-footer-container {
  position: absolute;
  left: 24px;
  top: 800px;
}

.main_table_provider {
  border-collapse: collapse;
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff;
}

thead {
  text-align: left;
  color: #94979a;
}

.providers_head{
  border:none
}
.main_table_provider th {
  padding: 12px;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  border: none;
}

.main_table_provider td {
  padding: 15px;
  font-size: 15px;
  color: #333333;
}

tr.main_table_inside_provider {
  border-top: 0.5px solid #cccfd8;
}

  .center {
    text-align: center;
    margin-top: 5px;
  }
  
  .full_bar {
    width: 98%;
    display: inline-block;
  }
  
  .right_end {
    width: 28%;
    display: inline-block;
  }
  
  .pagination {
    display: inline-block;
    width: 57%;
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 4px 4px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 0 4px;
  }
  
  .pagination a.active {
    background-color: #4caf50;
    color: white;
    border: 1px solid #4caf50;
  }
  
  .block_arrow {
    vertical-align: top;
  }
  
  .pagination a:hover:not(.active) {
    background-color: #ddd;
  }
  
  .select_p_page {
    background: #f5f5f5;
    width: 56px;
    height: 20px;
    text-align: center;
    border: 1px solid #dadada;
    box-sizing: border-box;
  }
  
.member_table {
  border-collapse: collapse;
  width: 95%;

  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 5px 4px 0px 0px;
}

thead {
  text-align: left;
  color: #94979a;
}
.member_table th {
  padding: 10px;
  font-size: 15px;
  color: #747587;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-transform: uppercase;
}

.member_table td {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;

  align-items: center;

  color: #333333;
}

.span_member {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-top: 3px;

  display: flex;

  align-items: center;

  color: #949292;
}

tr.main_table_inside {
  border-top: 1px solid #cccfd8;
}

td.main_table_inside {
  font-size: 55px;
}

.center {
  text-align: center;
  margin-top: 5px;
}

.right_end {
  text-align: end;
  width: 98%;
}

.pagination {
  display: inline-block;
  width: '200px';
}

.pagination a {
  color: black;
  float: left;
  padding: 4px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.back_to_m {
  width: 135px;
  height: 6.8vh;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 6.8vh;
  border: none;
  background-color: #fff;

  color: #747587;
}

.tab_btn {
  width: 114px;
  height: 44px;
  left: 32px;
  top: 123px;
  color: #a9afb0;
  opacity: 0.6;
  background: #fafafa;
  border: 1px solid #d3dae6;
}

.header_member {
  width: 100vw;
  height: 6.5vh;

  background-color: #111727;
}

/* width */
::-webkit-scrollbar {
  margin-top: 500px;
  width: 10px;
  height: 50px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;

  align-items: center;
  letter-spacing: 0.02em;

  color: #949292;
}

.values {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 0px;
  /* identical to box height */

  align-items: center;
  letter-spacing: 0.02em;

  color: #000000;
}

.input_edit_member {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 0px;
  /* identical to box height */
  display: block;
  align-items: center;
  letter-spacing: 0.02em;

  color: #000000;
}

.headings {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.showing {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  align-items: center;

  color: #949292;
}

.benefits {
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #353439;
}

.history_table {
  border-collapse: collapse;
  width: 100%;

  background-color: #fff;
}

thead {
  text-align: left;
  border-top: 1px solid #cccfd8;
  border-bottom: 1px solid #cccfd8;
}

.history_table th {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  align-items: center;
  padding: 10px;
  text-transform: uppercase;

  color: #949292;
}

.history_table td {
  padding: 10px;
  font-size: 12px;
  color: #333333;
}

tr.history_table_inside {
  border-bottom: 1px solid #cccfd8;
}

/*SIDEBAR THING */

.input_thingy {
  background: #fefefe;
  border: 1px solid #a9a9a9;
  box-sizing: border-box;
  height: 32px;
  border-radius: 4px;
}

.select_btn {
  width: 95px;
  height: 32px;
  opacity: 0.8;
  color: #cd1d33;
  font-size: 14px;
  line-height: 17px;
  border-radius: 6px;
  border: none;
  border: 1px solid #cd1d33;
  background: #fafafa;
}

.reset_btn {
  width: 95px;
  height: 32px;
  opacity: 0.8;
  border-radius: 6px;
  border: none;
  color: #747587;
  background-color: #fefefe;
  border: 1px solid #747587;
}

.accord_btn {
  background: #000;
  border: 1px solid #efefef;
  border-radius: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  padding-left: 10px;
  align-items: center;

  color: #949292;
  height: 32px;
}

.span_thing {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #949292;
}

.account_table {
  border-collapse: collapse;
  width: 100%;
  margin-top: -2%;

  background-color: #fff;
}

.account_table th {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  align-items: center;
  padding: 10px;
  text-transform: uppercase;

  color: #949292;
}

.account_table td {
  font-size: 14px;
  padding: 10px;
  line-height: 17px;
  color: #333333;
}

tr.account_table_inside {
  border-bottom: 1px solid #cccfd8;
}

.input_claim {
  background: #fff;
  border-bottom: 1px solid #e9eaf0;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  height: 28px;
}

.label_claims {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  color: #000000;
}

.values_left {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 5px;
  /* identical to box height */

  align-items: center;
  letter-spacing: 0.02em;

  color: #000000;
}

.left_deet_label {
  font-weight: 500;
  font-size: 11px;
  line-height: 0px;

  align-items: center;
  letter-spacing: 0.02em;

  /* Text / Grey */

  color: #9fa19f;
}

.add_transaction_btn {
  background: #fafafa;
  opacity: 0.8;
  /* Grey/Icon */
  height: 40px;
  width: 100%;

  border: 1px solid #747587;
  box-sizing: border-box;
  border-radius: 6px;
}

.left_deet_submit {
  margin-top: 20px;
  width: 96px;
  height: 40px;
  color: #fff;
  background: #cd1d33;
  border: none;
  border-radius: 6px;
}

.save_item {
  background: #ffffff;
  /* Grey/Icon */
  width: 89px;
  height: 24px;
  color:  #0A6BF0;

  border: none;
  box-sizing: border-box;
  border-radius: 4px;
}

.save_item_disabled{
  background: #ffffff;
  /* Grey/Icon */
  width: 89px;
  height: 24px;
  color:  gray;

  border: none;
  box-sizing: border-box
}

.display_result {
  margin-top: 5px;
  height: 190px;
  width: 300px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: scroll;
}

.display_icon {
  display: inline-flex;
}

.display_nothing {
  display: none;
}

.display_som{
  display:block
}

.display_result::-webkit-scrollbar {

}

.display_result .dataItem {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  color: black;
  font-size: 12px;
}

.dataItem h5 {
  margin-left: 10px;
}
h5 {
  font-weight: 300;
}

h5:hover {
  background-color: lightgrey;
}

.radio_value {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #353439;
}



.btn_onList{
background-color: blue;
border:none;
color:#fff;
border-radius: 4px;
}

.btn_notList{ background-color: green;
  border:none;
  color: #fff;
  border-radius: 4px;

}







/* deleted MUP Line Item */


.deleted{display:none}
.back_to_m {
  width: 135px;
  height: 6.8vh;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 6.8vh;
  border: none;
  background-color: #fff;

  color: #747587;
}

.click {
  display: block;
}

.header_member {
  width: 100vw;
  height: 6.5vh;

  background-color: #111727;
}

/* width */
::-webkit-scrollbar {
  margin-top: 500px;
  width: 10px;
  height: 50px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*AMP LEFT CSS */

.amp_left_heading {
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  margin-bottom: 40px;
  align-items: center;
  color: #111727;
}

.amp_left_pages {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000;
}

.amp_check {
  opacity: 0.3;
  margin-bottom: 24px;
}

.amp_check_active {
  opacity: 1;
  margin-bottom: 24px;
}

.amp_left_button {
  background: #cd1d33;
  border: none;
  color: #fff;
  margin-top: 75px;
  border-radius: 6px;
  width: 140px;
  height: 32px;
}

/*AMP PAGE 1 */

.amp_page1_heading {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #111727;
}

.amp_sub_heading {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #9fa19f;
}

.amp_label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* Text / Grey */

  color: #9fa19f;
}

.next_sect_btn {
  background: #ffffff;
  height: 28px;
  width: 146px;
  border: 1px solid #747587;
  color: #747587;
  box-sizing: border-box;
  border-radius: 6px;
}

.prev_sect_btn {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  background: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #a9afb0;
}

/*AMP PAGE 3*/

.p_writing {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;

  /* Text/Black */

  color: #353439;
}

.add_btn_l {
  width: 30px;
  height: 30px;
  border: 1px solid #747587;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
}

.main_table {
  border-collapse: collapse;
  width: 95%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

thead {
  text-align: left;
  color: #94979a;
}

.transactions_head{
  border:none
}
.main_table th {
  padding: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
}

.main_table td {
  padding: 10px;
  font-size: 15px;
  color: #333333;
}

tr.main_table_inside {
  border-top: 1px solid #cccfd8;
}

.member_table {
  border-collapse: collapse;
  width: 95%;

  background-color: #fff;

  border-radius: 5px 4px 0px 0px;
}

thead {
  text-align: left;
  color: #94979a;
}
.member_table th {
  padding: 10px;
  font-size: 15px;
  color: #747587;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-transform: uppercase;
}

.member_table td {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;

  align-items: center;

  color: #333333;
}

.span_member {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-top: 3px;

  display: flex;

  align-items: center;

  color: #949292;
}

tr.main_table_inside {
  border-top: 1px solid #cccfd8;
}

td.main_table_inside {
  font-size: 55px;
}

.center {
  text-align: center;
  margin-top: 5px;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 4px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;

  align-items: center;
  letter-spacing: 0.02em;

  color: #949292;
}

.values {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 0px;
  /* identical to box height */

  align-items: center;
  letter-spacing: 0.02em;

  color: #000000;
}

.headings {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.showing {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  align-items: center;

  color: #949292;
}

.benefits {
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #353439;
}

.history_table {
  border-collapse: collapse;
  width: 100%;

  background-color: #fff;
}

thead {
  text-align: left;
  border-top: 1px solid #cccfd8;
  border-bottom: 1px solid #cccfd8;
}

.history_table th {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  align-items: center;
  padding: 10px;
  text-transform: uppercase;

  color: #949292;
}

.history_table td {
  padding: 10px;
  font-size: 12px;
  color: #333333;
}

tr.history_table_inside {
  border-bottom: 1px solid #cccfd8;
}

/*SIDEBAR THING */

.input_thingy {
  background: #fefefe;
  border: 1px solid #a9a9a9;
  box-sizing: border-box;
  height: 32px;
  border-radius: 4px;
}

.select_btn {
  width: 95px;
  height: 32px;
  opacity: 0.8;
  color: #cd1d33;
  font-size: 14px;
  line-height: 17px;
  border-radius: 6px;
  border: none;
  border: 1px solid #cd1d33;
  background: #fafafa;
}

.reset_btn {
  width: 95px;
  height: 32px;
  opacity: 0.8;
  border-radius: 6px;
  border: none;
  color: #747587;
  background-color: #fefefe;
  border: 1px solid #747587;
}

.accord_btn {
  background: rgba(251, 250, 250, 0.8);
  border: 1px solid #efefef;
  border-radius: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  padding-left: 10px;
  align-items: center;
  text-transform: uppercase;

  color: #949292;
  height: 32px;
}

.span_thing {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #949292;
}

.account_table {
  border-collapse: collapse;
  width: 100%;
  margin-top: -2%;

  background-color: #fff;
}

.account_table th {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  align-items: center;
  padding: 10px;
  text-transform: uppercase;

  color: #949292;
}

.account_table td {
  font-size: 14px;
  padding: 10px;
  line-height: 17px;
  color: #333333;
}

tr.account_table_inside {
  border-bottom: 1px solid #cccfd8;
}

.input_claim {
  background: #fff;
  border-bottom: 1px solid #e9eaf0;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  height: 28px;
  color: #000;
}

.input_completed {
  background: #f3f3f3;
  border-bottom: 1px solid #e9eaf0;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  height: 28px;
  color: #9fa19f;
}

.input_submit {
  background: #fff;
  border-bottom: 1px solid #e9eaf0;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  height: 28px;
  color: #000;
}
.label_claims {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #9fa19f;
}

.values_left {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 5px;
  /* identical to box height */

  align-items: center;
  letter-spacing: 0.02em;

  color: #000000;
}

.left_deet_label {
  font-weight: 500;
  font-size: 11px;
  line-height: 0px;

  align-items: center;
  letter-spacing: 0.02em;

  /* Text / Grey */

  color: #9fa19f;
}

.add_transaction_btn {
  background: #fafafa;
  opacity: 0.8;
  /* Grey/Icon */
  height: 40px;
  width: 100%;

  border: 1px solid #747587;
  box-sizing: border-box;
  border-radius: 6px;
}

.left_deet_submit {
  margin-top: 20px;
  width: 96px;
  height: 40px;
  color: #fff;
  background: #cd1d33;
  border: none;
  border-radius: 6px;
}

.left_deet_assign_procedures {
  margin-top: 20px;
  width: 96px;
  height: 40px;
  color: #747587;
  background-color: #00000000;
  border: 1px solid #747587;
  border-radius: 6px;
}


.left_generate_lote_number {
  border: 1px solid #CD1D33;
  margin-top: 20px;
  width: 96px;
  height: 40px;
  color: #CD1D33 ;
  background-color: #00000000;
  border-radius: 6px;
}

.save_item_btn {
  background: #ffffff;
  /* Grey/Icon */
  width: 89px;
  height: 24px;

  border: 1px solid #747587;
  box-sizing: border-box;
  border-radius: 4px;
}


.edit_btn_active{ border:1px solid #90ee90;
  border-radius: 4px;
  width: 89px;
  height: 24px;
background-color: #90ee90 ;
box-sizing: border-box;}

.save_plan_btn {
  background: #cd1d33;
  border-radius: 6px;
  width: 140px;
  height: 32px;
  margin-top: 30px;
  border: none;
  color: #fff;
}

.save_acc {
  background: #ffffff;
  /* Grey/Outline */
  height: 32px;
  width: 56px;
  border: 1px solid #d3dae6;
}

.main_btn {
  width: 171px;
  height: 32px;
  border: 1px solid #d3dae6;
  background-color: #fefefe;
  color: #000;
  margin-left: 19vh;
}

.main_btn_clicked {
  background-color: #d3d3d3;
  width: 171px;
  height: 32px;
  border: 1px solid #d3dae6;
  color: #000;
  margin-left: 19vh;
}

.back_to_m {
    width: 135px;
    height: 6.8vh;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 6.8vh;
    border: none;
    background-color: #fff;
  
    color: #747587;
  }
  
  .tab_btn {
    width: 114px;
    height: 44px;
    left: 32px;
    top: 123px;
    color: #a9afb0;
    opacity: 0.6;
    background: #fafafa;
    border: 1px solid #d3dae6;
  }
  
  .header_member {
    width: 100vw;
    height: 6.5vh;
  
    background-color: #111727;
  }
  
  /* width */
  ::-webkit-scrollbar {
    margin-top: 500px;
    width: 10px;
    height: 50px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  

  .link_btn{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    
    color: #007CEE;
    }
.main_table_ptp {
    border-collapse: collapse;
    width: 95%;
    
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #fff;
  }
  
  thead {
    text-align: left;
    color: #94979a;
  }
  
  .ptp_head{
    border:none
  }
  .main_table_ptp th {
    padding: 14px;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    border: none;
  }
  
  .main_table_ptp td {
    padding: 14px;
    font-size: 12px;
    color: #333333;
  }
  
  tr.main_table_inside_ptp {
    border-top: 0.5px solid #cccfd8;
  }
  

  .link_btn{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    
    color: #747587
    }

    .values_left_ptp {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      /* identical to box height */
    
      align-items: center;
      letter-spacing: 0.02em;
    
      color: #000000;
    }
    

  .paid_status {
    border-radius: 5px;
    color: #fff;
    width: 40px;
    text-align: center;
    background-color: #13bc85;
    border: 1px solid #13bc85;
  }  

