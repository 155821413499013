.loader {
  border: 14px solid #f3f3f3;
  border-radius: 50%;
  border-top: 14px solid #cd1d33;
  border-bottom: 14px solid #cd1d33;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 0.9s linear infinite;
  animation: spin 0.9s linear infinite;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
  justify-content: center;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
