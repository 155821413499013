.member_table {
  border-collapse: collapse;
  width: 95%;

  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 5px 4px 0px 0px;
}

thead {
  text-align: left;
  color: #94979a;
}
.member_table th {
  padding: 10px;
  font-size: 15px;
  color: #747587;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-transform: uppercase;
}

.member_table td {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;

  align-items: center;

  color: #333333;
}

.span_member {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-top: 3px;

  display: flex;

  align-items: center;

  color: #949292;
}

tr.main_table_inside {
  border-top: 1px solid #cccfd8;
}

td.main_table_inside {
  font-size: 55px;
}

.center {
  text-align: center;
  margin-top: 5px;
}

.right_end {
  text-align: end;
  width: 98%;
}

.pagination {
  display: inline-block;
  width: '200px';
}

.pagination a {
  color: black;
  float: left;
  padding: 4px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}
