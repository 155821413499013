.main_table_provider {
  border-collapse: collapse;
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff;
}

thead {
  text-align: left;
  color: #94979a;
}

.providers_head{
  border:none
}
.main_table_provider th {
  padding: 12px;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  border: none;
}

.main_table_provider td {
  padding: 15px;
  font-size: 15px;
  color: #333333;
}

tr.main_table_inside_provider {
  border-top: 0.5px solid #cccfd8;
}

  .center {
    text-align: center;
    margin-top: 5px;
  }
  
  .full_bar {
    width: 98%;
    display: inline-block;
  }
  
  .right_end {
    width: 28%;
    display: inline-block;
  }
  
  .pagination {
    display: inline-block;
    width: 57%;
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 4px 4px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 0 4px;
  }
  
  .pagination a.active {
    background-color: #4caf50;
    color: white;
    border: 1px solid #4caf50;
  }
  
  .block_arrow {
    vertical-align: top;
  }
  
  .pagination a:hover:not(.active) {
    background-color: #ddd;
  }
  
  .select_p_page {
    background: #f5f5f5;
    width: 56px;
    height: 20px;
    text-align: center;
    border: 1px solid #dadada;
    box-sizing: border-box;
  }
  