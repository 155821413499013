.background {
  background: #fafafa;
  height: 102vh;
  width: 105%;
  margin-left: -20px;
  margin-top: -10px;
}
body {
  overflow: hidden;
}

.form {
  text-align: center;
  position: absolute;

  left: calc(50% - 520px / 2);
  top: calc(40% - 443px / 2 + 0.5px);
}

.form_outline {
  margin: auto;
  text-align: center;
  width: 448px;
  height: 400px;
  background: #fafafa;
  border: 4px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
}

.login_button {
  margin-top: 40px;
  width: 382px;
  height: 40px;
  background: #cd1d33;
  border-radius: 6px;
  border: none;
  font-size: 15px;
  cursor: pointer;
  color: #ffffff;
}

.input_username {
  width: 382px;
  margin-top: 34px;
  height: 42px;
  font-size: 15px;
  color: #a9afb0;
  border: 1px solid #d3dae6;
  box-sizing: border-box;
  border-radius: 5px;
}

.input_password {
  width: 382px;
  margin-top: 8px;
  height: 42px;
  color: #a9afb0;
  font-size: 15px;
  border: 1px solid #d3dae6;
  box-sizing: border-box;
  border-radius: 5px;
}

.Login_thing {
  margin-top: 40px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  align-items: center;
  color: #111727;
}
.login_subtitle {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height */
  text-align: center;
  align-items: center;
  color: #6a6a6a;
}

.image {
  position: absolute;
  margin-left: -180px;
  margin-top: -250px;
}

.panel {
  display: inline-block;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #ffffff;
}

.labels {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 0px;
  display: block;
  color: #4a4e53;
}
