.button_request {
  width: 96px;
  height: 32px;
  border: none;
  color: #fff;
  background: rgba(60, 119, 188, 0.9);
  border-radius: 5px;
  margin-left: 77px;
  display: inline-block;
}

.modal_title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  align-items: center;

  color: #111727;
}

.ModalBody {
  width: '50px';
  height: '200px';
  margin-left: 40px;
  margin-right: 40px;
}

.sub_header_head {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  /* Text / Grey */

  color: #9fa19f;
}

.new_member_btn {
  background-color: #cd1d33;
  border: none;

  color: #fff;
  border-radius: 6px;
  font-size: 15px;
  line-height: 18px;
  height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 10.5%;
}

.inputs {
  background: #fefefe;
  border: 1px solid #cbcfd4;
  box-sizing: border-box;
  display: block;
  margin-bottom: 24px;

  border-radius: 4px;
  color: #171f29;
  font-size: 13px;
  height: 30px;
}

.sub_title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #616161;
}

.submitt_btn {
  width: 104px;
  height: 32px;

  margin-top: 20px;

  color: #ffffff;
  background-color: #35af49;
  margin-right: 130px;
  border: 1px solid #35af49;
  border-radius: 5px;
}

.cancell_btn {
  width: 104px;
  height: 32px;
  background: #ffffff;
  margin-top: 20px;
  color: #2660a4;
  margin-right: 30px;

  border: 1px solid #fff;
  border-radius: 5px;
}

.submitTrans_header {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height */
  align-items: center;
  letter-spacing: 0.05em;
  /* App Bar */
  color: #111727;
}

.submitTrans_cancel {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  width: 80px;
  align-items: center;
  letter-spacing: 0.0125em;
  border: none;
  background-color: #fff;
  color: #747587;
}

.submitTrans_body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  align-items: center;
  letter-spacing: 0.05em;
  /* Text/Black */
  color: #353439;
}

.submitTrans_confirm {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  line-height: 18px;
  background-color: #fff;
  width: 180px;
  border: none;
  color: #0a6bf0;
}


.input_block{background: #FEFFFF;
  border: 1px solid #C8C8C8;
  height: 32px;
  box-sizing: border-box;
  border-radius: 2px;}


 .trans_selected{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.6;} 