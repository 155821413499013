.main_table_ptp {
    border-collapse: collapse;
    width: 95%;
    
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #fff;
  }
  
  thead {
    text-align: left;
    color: #94979a;
  }
  
  .ptp_head{
    border:none
  }
  .main_table_ptp th {
    padding: 14px;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    border: none;
  }
  
  .main_table_ptp td {
    padding: 14px;
    font-size: 12px;
    color: #333333;
  }
  
  tr.main_table_inside_ptp {
    border-top: 0.5px solid #cccfd8;
  }
  

  .link_btn{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    
    color: #747587
    }

    .values_left_ptp {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      /* identical to box height */
    
      align-items: center;
      letter-spacing: 0.02em;
    
      color: #000000;
    }
    

  .paid_status {
    border-radius: 5px;
    color: #fff;
    width: 40px;
    text-align: center;
    background-color: #13bc85;
    border: 1px solid #13bc85;
  }  