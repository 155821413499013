.label {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;

  align-items: center;
  letter-spacing: 0.02em;

  color: #949292;
}

.values {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 0px;
  /* identical to box height */

  align-items: center;
  letter-spacing: 0.02em;

  color: #000000;
}

.headings {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}

.showing {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  align-items: center;

  color: #949292;
}

.benefits {
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #353439;
}

.history_table {
  border-collapse: collapse;
  width: 100%;

  background-color: #fff;
}

thead {
  text-align: left;
  border-top: 1px solid #cccfd8;
  border-bottom: 1px solid #cccfd8;
}

.history_table th {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  align-items: center;
  padding: 10px;
  text-transform: uppercase;

  color: #949292;
}

.history_table td {
  padding: 10px;
  font-size: 12px;
  color: #333333;
}

tr.history_table_inside {
  border-bottom: 1px solid #cccfd8;
}

/*SIDEBAR THING */

.input_thingy {
  background: #fefefe;
  border: 1px solid #a9a9a9;
  box-sizing: border-box;
  height: 32px;
  border-radius: 4px;
}

.select_btn {
  width: 95px;
  height: 32px;
  opacity: 0.8;
  color: #cd1d33;
  font-size: 14px;
  line-height: 17px;
  border-radius: 6px;
  border: none;
  border: 1px solid #cd1d33;
  background: #fafafa;
}

.reset_btn {
  width: 95px;
  height: 32px;
  opacity: 0.8;
  border-radius: 6px;
  border: none;
  color: #747587;
  background-color: #fefefe;
  border: 1px solid #747587;
}

.accord_btn {
  background: rgba(251, 250, 250, 0.8);
  border: 1px solid #efefef;
  border-radius: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  padding-left: 10px;
  align-items: center;
  text-transform: uppercase;

  color: #949292;
  height: 32px;
}

.span_thing {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #949292;
}

.account_table {
  border-collapse: collapse;
  width: 100%;
  margin-top: -2%;

  background-color: #fff;
}

.account_table th {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  align-items: center;
  padding: 10px;
  text-transform: uppercase;

  color: #949292;
}

.account_table td {
  font-size: 14px;
  padding: 10px;
  line-height: 17px;
  color: #333333;
}

tr.account_table_inside {
  border-bottom: 1px solid #cccfd8;
}

.input_claim {
  background: #fff;
  border-bottom: 1px solid #e9eaf0;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  height: 28px;
  color: #000;
}

.input_completed {
  background: #f3f3f3;
  border-bottom: 1px solid #e9eaf0;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  height: 28px;
  color: #9fa19f;
}

.input_submit {
  background: #fff;
  border-bottom: 1px solid #e9eaf0;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  height: 28px;
  color: #000;
}
.label_claims {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #9fa19f;
}

.values_left {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 5px;
  /* identical to box height */

  align-items: center;
  letter-spacing: 0.02em;

  color: #000000;
}

.left_deet_label {
  font-weight: 500;
  font-size: 11px;
  line-height: 0px;

  align-items: center;
  letter-spacing: 0.02em;

  /* Text / Grey */

  color: #9fa19f;
}

.add_transaction_btn {
  background: #fafafa;
  opacity: 0.8;
  /* Grey/Icon */
  height: 40px;
  width: 100%;

  border: 1px solid #747587;
  box-sizing: border-box;
  border-radius: 6px;
}

.left_deet_submit {
  margin-top: 20px;
  width: 96px;
  height: 40px;
  color: #fff;
  background: #cd1d33;
  border: none;
  border-radius: 6px;
}

.left_deet_assign_procedures {
  margin-top: 20px;
  width: 96px;
  height: 40px;
  color: #747587;
  background-color: #00000000;
  border: 1px solid #747587;
  border-radius: 6px;
}


.left_generate_lote_number {
  border: 1px solid #CD1D33;
  margin-top: 20px;
  width: 96px;
  height: 40px;
  color: #CD1D33 ;
  background-color: #00000000;
  border-radius: 6px;
}

.save_item_btn {
  background: #ffffff;
  /* Grey/Icon */
  width: 89px;
  height: 24px;

  border: 1px solid #747587;
  box-sizing: border-box;
  border-radius: 4px;
}


.edit_btn_active{ border:1px solid #90ee90;
  border-radius: 4px;
  width: 89px;
  height: 24px;
background-color: #90ee90 ;
box-sizing: border-box;}