.main-page {
  background-color: hsl(0, 0%, 98%);
  height: 99vh;
}

body {
  height: 100vh;

  cursor: default;
}

.header {
  width: 100vw;
  height: 5.5vh;

  background-color: #111727;
}

nav {
  width: 100vw;
  height: 5.5vh;
  background-color: #111727;
}

.div_header {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 5.5vh;
  align-items: center;
}

.logo {
  height: 40px;
  width: 116px;

  display: block;
}

.p_header {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  width: 100px;
  display: inline-flex;
  align-items: center;

  color: #ffffff;
}

.active {
  border-bottom: 4px solid #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  width: 100px;
  display: inline-flex;
  align-items: center;

  color: #ffffff;
  height: 29px;
}

.btn_header {
  background: #111727;
  border: none;
  border-left: 1px solid #ffffff;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  height: 5.5vh;
  display: inline-flex;
  align-items: center;
  width: 147px;
}

.subheader {
  width: 99.85%;
  height: 17.4%;
  margin-top: 2px;
  background-color: #fff;
}

.top_pagebox {
  width: 95%;
  height: 85%;
}

.subheader_heading {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 0px;
  /* identical to box height */

  align-items: center;

  color: #000000;
}

.labels {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  align-items: center;
  letter-spacing: 0.02em;
  color: #353439;
  opacity: 0.9;
}

.inputs_sidebar {
  background: #fefefe;
  border: 1px solid #cbcfd5;
  box-sizing: border-box;
  border-radius: 4px;
  color: #171f29;
  font-size: 13px;

  height: 28px;
}

.search_btn {
  width: 115px;
  height: 28px;
  border: none;
  border-radius: 6px;
  border: 1px solid #cd1d33;

  background-color: #fff;
  color: #cd1d33;
}

.trash_btn {
  height: 40px;
  background-color: #fff;
  color: #747587;
  border: 1px solid #747587;
  box-sizing: border-box;
  border-radius: 6px;
}

.tab_block {
  width: auto;
  border: none;
  background-color: #fff;
}

.tab_h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #333333;
}

.add_plan_btn {
  background-color: #cd1d33;
  border: none;

  color: #fff;
  border-radius: 6px;
  font-size: 15px;
  line-height: 18px;
  height: 32px;
  width: 150px;
  display: flex;
  align-items: center;
  text-align: center;
}

.new_member_btn {
  background-color: #cd1d33;
  border: none;

  color: #fff;
  border-radius: 6px;
  font-size: 15px;
  line-height: 18px;
  height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 10.5%;
}
