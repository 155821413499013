.main_table {
  border-collapse: collapse;
  width: 95%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

thead {
  text-align: left;
  color: #94979a;
}

.transactions_head{
  border:none
}
.main_table th {
  padding: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
}

.main_table td {
  padding: 10px;
  font-size: 15px;
  color: #333333;
}

tr.main_table_inside {
  border-top: 1px solid #cccfd8;
}
